import React from "react";
import {Link} from "react-router-dom";

export const OrientationPage = () => {

    return (
        <div className={'App'} style={{marginTop: '5rem'}}>

            <Link to={'/questions'}>
                <button id="LoginSubmit" type="submit" className="edit-button save enter"
                style={{height:'300px',width:'30%',fontSize:50}}>
                    Questions
                </button>
            </Link>
            <Link to={'/message'}>
                <button id="LoginSubmit" type="submit" className="edit-button save info"
                        style={{height:'300px',width:'30%',marginLeft: '2rem', fontSize:50}}>
                    Messaging
                </button>
            </Link>
        </div>
    )
}