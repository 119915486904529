
import React from 'react';

const Modal = ({ handleClose, show, children }) => {

  return (
    <div className={show ? "modal display-block" : "modal display-none"}>
      <section className="modal-main">
       <button className="modal-close" onClick={handleClose}>close</button>
        {children}        
      </section>
    </div>
  );
};
export default Modal