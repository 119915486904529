import React, {useEffect, useState} from 'react'
import {Link, Route, useLocation} from "react-router-dom";
import {Auth} from "@aws-amplify/auth";


const isLoggedIn = {current: undefined, isFirstLoad: true};

export const useIsAuthenticated = () => {
    const [, setIsSigningIn] = React.useState(true);
    let location = useLocation();
    React.useEffect(() => {
        (async () => {
            let user = null;
            setIsSigningIn(user);
            try {
                user = await Auth.currentAuthenticatedUser();
                isLoggedIn.isFirstLoad = false;
                if (!isLoggedIn.current || isLoggedIn.current?.username !== user.username) {
                    isLoggedIn.current = user;
                    setIsSigningIn(user);
                }
            } catch (e) {
                isLoggedIn.current = undefined;
                isLoggedIn.isFirstLoad = false;
                setIsSigningIn(false);
            }
        })();
    }, [location?.pathname]);

    return isLoggedIn.current;
}

function PrivateRoute({children, ...rest}) {

    let location = useLocation();

    const [isAuthenticated, setAuthenticated] = useState(isLoggedIn.current);

    useEffect(() => {
        Auth.currentAuthenticatedUser().then(value => {
            isLoggedIn.current = value;
            setAuthenticated(value);
        }).catch(reason => {
            isLoggedIn.current = null;
            setAuthenticated(null);
        })
    }, [])


    return (!isAuthenticated && location.pathname != '/login' ?
            // <Redirect to={'/login'}/>
            <div style={{margin: 'auto', width: '100%', marginTop: '10%'}}>
                <h2 style={{width: '100%', textAlign: 'center'}}>
                    <Link to={'/Login'}>Login required</Link>
                </h2>
            </div>
            :
            <Route {...rest} render={() => {
                return children;
            }}/>
    )
}


export default PrivateRoute;