import React, {Fragment, useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import './login.css';
import {Auth} from "@aws-amplify/auth";
import ClipLoader from "react-spinners/ClipLoader";

const iconLogo = require('./icons/myExperienceLogo.png');

const Login = ({loginType}) => {

    const history = useHistory();

    const [state, setState] = useState({
        email: '',
        password: '',
        validCredentials: true,
        loggedIn: false,
        newPassword: null
    });

    const [loading, setLoading] = useState(false)

    const [errorMessage, setErrorMessage] = useState('');


    useEffect(() => {
        /*Auth.currentAuthenticatedUser().then(value => {
            setState({...state, loggedIn: true})
        }).catch(reason => {
            setState({...state, loggedIn: false})
        })*/
        console.log('LOGIN TYPE', loginType)
    }, [])

    const onChangeEmail = (event) => {
        var email = event.target.value
            .toLowerCase()
        setState({...state, email: email});
    }

    const onChangePassword = (event) => {
        setState({...state, password: event.target.value});
    }

    const onChangeNewPassword = (event) => {
        setState({...state, newPassword: event.target.value});
    }


    const onSubmitForm = async (event) => {
        event.preventDefault();
        setLoading(true);

        const email = state.email;
        const pass = state.password;

        Auth.signIn(email, pass).then(value => {
            console.log(value)
            if (value.challengeName === 'NEW_PASSWORD_REQUIRED' && loginType !== 1) {
                history.push('/activate')
                setLoading(false);
                return;
            }
            // setState({...state, validCredentials: true, loggedIn: true});
            if (loginType === 1) {
                Auth.completeNewPassword(value, state.newPassword)
                    .then(value => {
                        setErrorMessage('Successfully activated account')
                        setLoading(false);
                        history.push('/orientation')
                        console.log(value)
                    })
                    .catch(reason => {
                        setErrorMessage('Failed to activate account')
                        setLoading(false);
                        console.log(reason)
                    });
            }

            if (loginType === 2) {
                Auth.changePassword(value, state.password, state.newPassword)
                    .then(value1 => {
                        setErrorMessage('Successfully Changed Password')
                        setLoading(false);
                        history.push("/orientation")
                    }).catch(reason => {
                    setErrorMessage('Failed to change password')
                    setLoading(false);
                })
            }

            if (loginType === 0) {
                setLoading(false);
                history.push("/orientation");
            }
        }).catch(reason => {
            console.log(reason)
            setErrorMessage('Invalid username or password');
            setLoading(false)
            setState({...state, validCredentials: false});
        });
    }


    //already logged in
    // if (state.loggedIn) {
    //     console.log("in login render redirect to home");
    //     return history.push("/");
    // }

    return (
        <Fragment>
            <div className="App">
                <h1>{loginType === 0 ? 'Login' : loginType ===1?'Activate':'Change Password'}</h1>
                <h3>{errorMessage}</h3>
                {/*<div>
                    <label className="login-prompt">First Login</label>
                    <input name='loginType' type='radio' onChange={() => onClickCheckbox(1)}/><br/>
                    <label className="login-prompt">Change Password</label>
                    <input name='loginType' type='radio' onChange={() => onClickCheckbox(2)}/><br/>
                </div>*/}
                <form id="LoginForm" onSubmit={onSubmitForm} className="login-form">
                    <label className="login-prompt">Email</label><br/>
                    <input id="email" type="email" className="edit-field" required
                           onChange={event => onChangeEmail(event)}></input><br/><br/>

                    <label className="login-prompt">{loginType === 0 ? 'Password' : 'Old Password'}</label><br/>
                    <input id="password" className="edit-field" type="password" required
                           onChange={event => onChangePassword(event)}></input><br/><br/>

                    {loginType > 0 &&
                    <>
                        <label className="login-prompt">New Password (8 characters, include uppercase, lowercase numbers and speacial characters.)</label><br/>
                        <input id="password" className="edit-field" type="password" required
                               onChange={event => onChangeNewPassword(event)}></input><br/><br/>
                    </>
                    }

                    <div style={{display: 'block', position: 'relative', marginLeft: '42%'}}>
                        <ClipLoader loading={loading} size={30}/>
                    </div>
                    <Link to='/forgot-password'>Forgot Password</Link><br/>
                    <button id="LoginSubmit" type="submit" className="edit-button save enter">
                        enter
                    </button>
                </form>
                <div><img className="img-logo-login img-logo" src={iconLogo}/></div>
            </div>
        </Fragment>
    );

}

export default Login;
