import React, {useState} from 'react';
import {Auth} from "@aws-amplify/auth";
import {useHistory} from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

export const ForgotPasswordActivate = () => {

    const [isSpinning, setSpinner] = useState(false);
    const [state, setState] = useState({email: '', code: '', newPassword: ''});

    const history = useHistory();

    const submit = () => {
        setSpinner(true);
        Auth.forgotPasswordSubmit(state.email, state.code, state.newPassword).then(value => {
            history.push('/login')
        }).catch(reason => {
            alert(reason);
            console.log(reason);
        }).finally(() => {
            setSpinner(false);
        });
    }

    return (
        <div className={'App'} style={{marginTop: '5rem'}}>
            <label className="login-prompt">Email</label><br/>
            <input id="email" className="edit-field" type="email" required
                   onChange={event => setState({...state,email: event.target.value})}></input><br/><br/>

            <label className="login-prompt">Password</label><br/>
            <input id="password" className="edit-field" type="password" required
                   onChange={event => setState({...state,newPassword: event.target.value})}></input><br/><br/>

            <label className="login-prompt">Code</label><br/>
            <input id="code" className="edit-field" type="text" required
                   onChange={event => setState({...state,code: event.target.value})}></input><br/><br/>


                   <div style={{display: 'block', position: 'relative', marginLeft: '42%'}}>
                <ClipLoader loading={isSpinning} size={30}/>
            </div>
            <button id="LoginSubmit" type="submit" className="edit-button save enter" onClick={() => submit()}>
                enter
            </button>
        </div>
    )
}