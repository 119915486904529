import React from 'react';
import {Route, Switch} from 'react-router-dom'
import Login from './Login';
import List from './List';
import Home from './Home';
import PrivateRoute from "../routes/ProtectedRoute";
import MessageForm from "./MessageForm";
import {ForgotPassword} from "./ForgotPassword";
import {ForgotPasswordActivate} from "./ForgotPasswordActivate";
import {OrientationPage} from "./OrientationPage";

const Main = (props) => {


    return (<main>
        <Switch>
            <Route path='/Login'>
                <Login loginType={0}/>
            </Route>
            <Route path='/activate' exact>
                <Login loginType={1}/>
            </Route>
            <Route path='/forgot-password' exact>
                <ForgotPassword/>
            </Route>
            <Route path='/forgot-password-activate' exact>
                <ForgotPasswordActivate/>
            </Route>


            <PrivateRoute exact path='/questions'>
                <Home
                    logInUser={props.logInUser}
                    logoutUser={props.logoutUser}
                    loggedIn={props.loggedIn}/>
            </PrivateRoute>
            <PrivateRoute exact path='/orientation'>
                <OrientationPage/>
            </PrivateRoute>
            <PrivateRoute path="/list">
                <List/>
            </PrivateRoute>
            <PrivateRoute path="/message">
                <MessageForm/>
            </PrivateRoute>
            <PrivateRoute>
                <Route path='/change-password'>
                    <Login loginType={2}/>
                </Route>
            </PrivateRoute>
        </Switch>
    </main>);
}
export default Main