import React, {useState} from 'react';
import ClipLoader from "react-spinners/ClipLoader";

const Childhood_Title_1 = 'Childhood_Title_1'
const Childhood_Title_2 = 'Childhood_Title_2'
const Childhood_Title_3 = 'Childhood_Title_3'
const Childhood_Title_4 = 'Childhood_Title_4'

const Work_Title_1 = 'Work_Title_1'
const Work_Title_2 = 'Work_Title_2'
const Work_Title_3 = 'Work_Title_3'
const Work_Title_4 = 'Work_Title_4'

const Wisdom_Title_1 = 'Wisdom_Title_1'
const Wisdom_Title_2 = 'Everyone is excited to hear your wisdom!'
const Wisdom_Title_3 = 'Wisdom_Title_3'
const Wisdom_Title_4 = 'Wisdom_Title_4'

const titleMappings = {
    100: [Childhood_Title_1, Childhood_Title_2, Childhood_Title_3, Childhood_Title_4],
    200: [Work_Title_1, Work_Title_2, Work_Title_3, Work_Title_4],
    300: [Wisdom_Title_1, Wisdom_Title_2, Wisdom_Title_3, Wisdom_Title_4],
    400: ['Title4', "Title2", "Title3", "Title4", "Title1"],
}

function titleCase(str) {
    str = str.toLowerCase().split(' ');
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1); 
    }
    return str.join(' ');
  }

const MessageForm = () => {

    const CUSTOM_TITLE_OPTION = 500;
    const backendUrl = 'https://admin.itismyexperience.com/message';

    const [state, setState] = useState({
        multiple: false,
        multipleMails: '',
        category: 100,
        titleOption: 0,
        titleText: titleMappings[100][0],
        message: '',
        caption: ''
    })
    const [loading, setLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');

    const changeState = (field, value) => {
        if (field === 'multiple') {
            // reset multipleMails field on change
            setState({
                ...state,
                'multipleMails': '',
                [field]: value
            });
        } else {
            setState({
                ...state,
                [field]: value
            });
        }

    }

    const changeTitle = (titleOption)=>{
        if(titleOption === CUSTOM_TITLE_OPTION){
            setState({
                ...state,
                titleOption: titleOption,
                titleText: ''
            });
        }else{
            setState({
            ...state,
            titleOption: titleOption,
            titleText: titleMappings[state.category][titleOption]
            });
        }
        }

    const submitForm = () => {
        setLoading(true);
        let title = state.titleText;
          if(state.titleOption != CUSTOM_TITLE_OPTION) {
            title = titleMappings[state.category][state.titleOption]
          }

        const body = JSON.stringify({
            allusers: state.multiple,
            users: state.multipleMails,
            category_id: state.category.toString(),
            title: title,
            message: state.message,
            caption: titleCase(state.caption)
        });
        debugger;
        fetch(backendUrl, {
            method: 'POST',
            headers: {
                //todo: Change if content type is URL Encoded
                'Content-Type': 'application/json'
            },
            body: body
        }).then(value => {
            setStatusMessage('Success !');
        }).catch(err => {
            setStatusMessage(err.message)
        }).finally(() => {
            setLoading(false)
        })

    }


    return (
        <div style={{width: '50vw', fontSize: 18}} className='flex-container'>

            <div style={{display: 'block'}}>

                <h2>Message Form</h2>

                <div style={{border: '1px solid', padding: '10px'}}>
                    <div style={{display: 'block'}}>
                        <label>All users</label>
                        <input type='radio'
                               name='radio-user'
                               className="form-radio"
                               onChange={() => changeState('multiple', true)}/>
                    </div>
                    <div style={{display: 'block'}}>
                        <label>One user</label>
                        <input type='radio'
                               name='radio-user'
                               className="form-radio"
                               onChange={() => changeState('multiple', false)}
                        />
                    </div>

                    {/* IF MULTIPLE MAILS, INPUT FIELD APPEARS*/}
                    {!state.multiple &&
                    <div style={{display: 'block', marginTop: '30px'}}>
                        <label>Enter users email address here. For mutiple users, separate emails with a comma.</label>
                        <input type='text'
                               style={{display: 'block'}}
                               onChange={e => changeState('multipleMails', e.target.value)}
                        />
                    </div>
                    }
                </div>

                {/* CATEGORY */}
                <div style={{marginTop: '30px'}}>
                    <label>Category</label>
                    <select style={{width: '100%', fontSize: 17}} className='ddl-categories'
                            onChange={e => changeState('category', e.target.value)}>
                        <option value={100}>Childhood</option>
                        <option value={200}>Work</option>
                        <option value={300}>Wisdom</option>
                    </select>
                </div>

                {/* TITLE */}
                <div style={{marginTop: '30px'}}>
                    <label>Notification Title</label>
                    <select style={{width: '100%', fontSize: 17}} className='ddl-categories'
                            	onChange={e => changeTitle(e.target.value)}>
                        {
                            // Generating titles for given Category
                            titleMappings[state.category].map((title, i) => {
                                return <option value={title} key={i}>{title}</option>
                            })
                        }
                        <option value={CUSTOM_TITLE_OPTION}>Custom</option>
                    </select>
                    {/* Custom title - free text entry */}
                    {state.titleOption == CUSTOM_TITLE_OPTION &&
                    <div style={{display: 'block', marginTop: '30px'}}>
                        <label>Custom title</label>
                        <textarea
                            style={{display: 'block'}}
                            onChange={e => changeState('titleText', e.target.value)}
                        />
                    </div>
                    }
                </div>

                {/* MESSAGE */}
                <div style={{marginTop: '30px'}}>
                    <label>Story Prompt</label>
                    <textarea
                        style={{display: 'block', width: '100%', fontSize: 15}}
                        onChange={e => changeState('message', e.target.value)}
                    />
                </div>


                {/* CAPTION */}
                <div style={{marginTop: '30px'}}>
                    <label>Story Caption</label>
                    <textarea
                        style={{display: 'block', width: '100%', fontSize: 15}}
                        onChange={e => changeState('caption', e.target.value)}
                    />
                </div>

                <button style={{marginTop: '30px', display: 'inline-flex'}} onClick={submitForm}>
                    Submit
                </button>
                <div style={{display: 'block', position: 'relative', marginLeft: '42%', marginTop: '1.2rem'}}>
                    <ClipLoader loading={loading} size={40}/>
                </div>

                <h4>{statusMessage}</h4>
            </div>

        </div>
    )
}

export default MessageForm;