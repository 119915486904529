import React, {Component, Fragment} from 'react';
import {clone} from "ramda";
import './home.css';
import Modal from './AddQuestionModal'
import * as XLSX from 'xlsx';
import {Link} from "react-router-dom";

const iconCamera = require('./icons/camera.png');
const iconPlay = require('./icons/play.png');
const iconLogo = require('./icons/myExperienceLogo.png');

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loggedIn: this.props.loggedIn,
            isLoaded: false,
            categories: [],
            questions: [],
            selectedCategory: '',
            renderQuestionTableBody: [],
            categoriesDDL: [],
            categoriesCount: 0,
            deleteMode: false,
            addMode: false,
            editMode: false,
            reorderMode: false,
            addAnotherMode: false,
            questionsToBeDeleted: [],
            questionsToBeUpdated: new Map([]),
            questionsToBeAdded: [],
            questionToBeAdded: {},
            idWantToMoveTo: 0,
            idWantToMove: 0,
            uniqueKey: 1524,
            isQuestionsForCategory: false,
            clonedQuestions: [],
            itemInDrag: 0,
            show: false,
            questionFile: ''
        };
        this.fetchCategories = this.fetchCategories.bind(this);
        this.onChangeDropDownSelection = this.onChangeDropDownSelection.bind(this);
        this.onDeleteQuestion = this.onDeleteQuestion.bind(this);
        this.onAddQuestion = this.onAddQuestion.bind(this);
        this.onEditQuestion = this.onEditQuestion.bind(this);
        this.onReorderQuestion = this.onReorderQuestion.bind(this);
        this.renderCategoriesDDL = this.renderCategoriesDDL.bind(this);
        this.renderQuestionTableBody = this.renderQuestionTableBody.bind(this);
        this.onSubmitChanges = this.onSubmitChanges.bind(this);
        this.onCheckQuestion = this.onCheckQuestion.bind(this);
        this.onChangeCaption = this.onChangeCaption.bind(this);
        this.onChangePrompt = this.onChangePrompt.bind(this);
        this.onChangeNewQuestionCaption = this.onChangeNewQuestionCaption.bind(this);
        this.onChangeNewQuestionPrompt = this.onChangeNewQuestionPrompt.bind(this);
        this.onChangeIdToMove = this.onChangeIdToMove.bind(this);
        this.onChangeIdToMoveTo = this.onChangeIdToMoveTo.bind(this);
        this.onPlusReOrder = this.onPlusReOrder.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.handleDragStart = this.handleDragStart.bind(this);
        this.handleDragEnter = this.handleDragEnter.bind(this);
        this.handleDragOver = this.handleDragOver.bind(this);
        this.handleDragLeave = this.handleDragLeave.bind(this);
        this.handleDragEnd = this.handleDragEnd.bind(this);
    }

    deleteChkToggle = (e) => {
        var checked = e.target.checked;
        var checkboxes = document.getElementsByName('checkbox');
        var selectedQuestions = [];
        console.log("checked: " + checked);
        if (checked === true) {
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = checked;
                selectedQuestions.push(checkboxes[i].value);

            }
            this.setState({questionsToBeDeleted: selectedQuestions});
        } else {
            for (var i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = checked;
            }
            this.setState({questionsToBeDeleted: []});
        }
        console.log("questions to be deleted: " + this.state.questionsToBeDeleted)
    }

    showModal = () => {
        this.setState({show: true});
    };

    hideModal = (e) => {
        //reset textareass
        // document.getElementById("modal-prompt").value="";
        // document.getElementById("modal-caption").value="";
        this.setState({show: false});
    };

    onChangeFile = (e) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, {type: 'binary'});
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_row_object_array(ws);
            /* Update state */
            const jsonData = JSON.stringify(data);
            console.log(jsonData);
            const newQuestions = JSON.parse(jsonData);
            this.setState({questionsToBeAdded: newQuestions});
        }
        reader.readAsBinaryString(e.target.files[0]);
    }
    readAndSaveFile = (e) => {
        if (this.state.questionsToBeAdded.length !== 0) {
            var nextPosition = this.state.questions.length !== 0 ?
                this.state.questions[this.state.questions.length - 1].position + 1 : 100

            var questions = this.state.questionsToBeAdded;
            var newQuestions = [];
            questions.forEach((q) => {
                var ques = {
                    position: nextPosition++, categoryId: this.state.selectedCategory,
                    caption: q.Caption, prompt: q.Question
                };
                newQuestions.push(ques);
            })
            this.setState({questionsToBeAdded: newQuestions}, () => {
                fetch("/addQuestions", {
                        method: "POST",
                        body: JSON.stringify({questions: this.state.questionsToBeAdded}),
                        headers: {
                            "Content-Type": "application/json"
                        },
                        withCredentials: true,
                        credentials: 'include'
                    }
                ).then(response => {
                        if (response.status === 201) {
                            console.log("in response =201");
                            window.alert("submission to database successful");
                            this.fetchQuestions();
                            this.setState({show: false, addMode: false, questionsToBeAdded: []});

                        } else if (response.status === 404) {
                            console.log("in response =404");
                            alert("error 404: add unsuccessful");
                        } else if (response.status === 401) {
                            console.log("error 401: not authenticated to enter");
                            this.props.logoutUser();
                            this.setState({loggedIn: false});
                            alert("logging out. session expired.");
                            throw new Error("not authenticated to enter")
                        }
                    }
                ).catch((e) => {
                    console.log("add unsuccessful")
                    console.log(e);
                })
            });

        } else {
            alert("no file uploaded");
        }

    }

    saveAndAddAnother = (e) => {
        this.setState({addAnotherMode: true});
        this.saveNewQuestion(e);
    }
    saveAndClose = (e) => {
        this.setState({addAnotherMode: false});
        this.saveNewQuestion(e);
    }
    saveNewQuestion = (e) => {
        var newques = this.state.questionToBeAdded
        var position;
        if (this.state.isQuestionsForCategory === false) {
            console.log("in no questions for category save and close")
            position = 100;
        } else {
            position = (this.state.questions[this.state.questions.length - 1].position) + 1;
        }
        newques.position = position;

        this.setState({questionToBeAdded: newques});
        this.onSubmitChanges();
    }

    async componentDidMount() {
        await fetch("/validUser", {
            method: "POST",
            withCredentials: true,
            credentials: 'include'
        })
            .then((response) => {
                if (response.status === 201) {
                    this.props.logInUser();
                    this.setState({isLoaded: true, loggedIn: true});
                    console.log(this.state.loggedIn);
                    this.fetchCategories();
                } else if (response.status === 401) {
                    //todo: Is this needed ??
                    // console.log("error 401: not authenticated to enter")
                    // this.props.logoutUser();
                    // this.setState({loggedIn: false, isLoaded: true});
                    // return;
                }
            }).catch(reason => {
                console.log('Could not reach validUser')
            })
    }

    async onEditQuestion() {
        var x = document.getElementsByClassName('prompt-caption');
        if (!this.state.editMode) {
            await this.setState({
                addMode: false,
                deleteMode: false,
                reorderMode: false,
                editMode: true,
                questionToBeUpdated: new Map([])
            });
            this.renderQuestionTableBody(this.state.questions);

            for (var i = 0; i < x.length; i++) {
                x[i].classList.add('edit-focus');
            }
        } else {
            await this.setState({editMode: false});
            this.renderQuestionTableBody(this.state.questions);

            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove('edit-focus');
            }
        }
    }

    async onDeleteQuestion() {

        if (!this.state.deleteMode) {
            await this.setState({
                addMode: false,
                deleteMode: true,
                reorderMode: false,
                editMode: false,
                questionsToBeDeleted: []
            });
            this.renderQuestionTableBody(this.state.questions);
        } else {
            await this.setState({deleteMode: false});
            this.renderQuestionTableBody(this.state.questions);
        }
    }


    async onAddQuestion() {

        var x = document.getElementsByClassName('prompt-caption');
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove('edit-focus');
        }

        if (!this.state.addMode) {

            await this.setState({addMode: true, deleteMode: false, reorderMode: false, editMode: false});
            this.showModal();
            var question = {categoryId: this.state.selectedCategory, prompt: '', caption: '', position: -1};
            this.setState({questionToBeAdded: question});
            return;
        } else {
            await this.setState({addMode: false});
            this.renderQuestionTableBody(this.state.questions);
        }
    }

    async onReorderQuestion() {
        if (!this.state.reorderMode) {
            var uniqueKey = this.state.uniqueKey + 1
            await this.setState({
                addMode: false,
                deleteMode: false,
                reorderMode: true,
                editMode: false,
                uniqueKey: uniqueKey
            });
            this.renderQuestionTableBody(this.state.questions);
        } else {
            await this.setState({reorderMode: false});
            await this.fetchQuestions();
            this.renderQuestionTableBody(this.state.questions);
        }
    }

    onChangeIdToMoveTo(event) {
        var chosenId = event.target.value;
        console.log("id to move to" + chosenId);
        this.setState({idWantToMoveTo: chosenId});
    }

    onChangeIdToMove(event) {
        var chosenId = event.target.value;
        console.log("id to move" + chosenId);
        this.setState({idWantToMove: chosenId});
    }

    onPlusReOrder(event) {
        event.preventDefault();

        console.log("in onplusReOrder");
        var idToMove = parseInt(this.state.idWantToMove);
        var idToMoveItTo = parseInt(this.state.idWantToMoveTo);
        //make deep copy of this state.questions
        var questions = this.state.clonedQuestions;
        //check if equal 0
        console.log("before if in onplusreorder");
        if (idToMoveItTo === questions[questions.length - 1].position + 1) {
            console.log("in id = last+1");
            //move everything up
            questions.map(question => {
                //if equals id to move change position to the one you want to move it to
                if (question.position === idToMove) {
                    question.position = questions[questions.length - 1].position
                } else if (question.position > idToMove) {
                    question.position -= 1
                }
            });
        }
        //check if moving up or down
        else if (idToMove > idToMoveItTo) {
            console.log("in id >move");
            //moving up
            questions.map(question => {
                //if equals id to move change position to the one you want to move it to
                if (question.position === idToMove) {
                    console.log("in make the move!");
                    question.position = idToMoveItTo;
                }
                //if less than id to move and greater than the move to
                else if (question.position < idToMove && question.position >= idToMoveItTo) {
                    question.position += 1;
                }

            });
        } else if (idToMove < idToMoveItTo) {
            console.log("in id <move");
            questions.map(question => {
                //moving dow

                //if equals id to move change position to the one you want to move it to
                if (question.position === idToMove) {
                    console.log("in make the move!");
                    question.position = idToMoveItTo - 1;
                }
                //everything greater than the move  and less than to the move to 
                else if (question.position > idToMove && question.position < idToMoveItTo) {
                    console.log("in subtract position");
                    question.position -= 1;
                }
            });
        } else {
            console.log("no conditions met in onplusreorder")
        }
        console.log("after ifs in on plus reorder");
        this.renderQuestionTableBody(questions.sort((a, b) => (a.position > b.position) ? 1 : -1));
        var uniqueKey = this.state.uniqueKey + 1;
        this.setState({clonedQuestions: questions, uniqueKey: uniqueKey});


    }

    onChangeNewQuestionPrompt(event) {
        var newQues = this.state.questionToBeAdded;
        newQues.prompt = event.target.value;
        console.log("newQues: " + newQues.prompt);
        this.setState({questionToBeAdded: newQues});
        console.log("question to be added: " + this.state.questionToBeAdded.prompt + ", " +
            this.state.questionToBeAdded.caption + ", " + this.state.questionToBeAdded.categoryId + ", " + this.state.questionToBeAdded.position);
    }

    onChangeNewQuestionCaption(event) {
        var newQues = this.state.questionToBeAdded;
        newQues.caption = event.target.value;
        console.log("newQues: " + newQues.caption);
        this.setState({questionToBeAdded: newQues});
        console.log("question to be added: " + this.state.questionToBeAdded);
    }

    onChangeCaption(event) {
        /*update :1 means caption updated. 
        2 means prompt updated
        3 means both*/
        var questionsToBeUpdated = this.state.questionsToBeUpdated;
        if (this.state.questionsToBeUpdated.get(event.target.name) == null) {
            console.log("this question was never changed before");
            var question = {category: this.state.selectedCategory, update: 1, prompt: '', caption: event.target.value};
            questionsToBeUpdated.set(event.target.name, question);
            this.setState({questionsToBeUpdated: questionsToBeUpdated});
        } else {
            var questionToBeUpdated = this.state.questionsToBeUpdated.get(event.target.name);
            ;
            var updateType = questionToBeUpdated.update;
            if (updateType === 1 || updateType === 3) {
                console.log("just reset caption");
                //just reset caption
                questionToBeUpdated.caption = event.target.value;
            } else { //must be that equals two
                console.log("reset to 3 and reset caption");
                //reset update to 3 because now both are being update
                questionToBeUpdated.update = 3;
                questionToBeUpdated.caption = event.target.value;
            }
            questionsToBeUpdated.set(event.target.name, questionToBeUpdated)
            this.setState({questionsToBeUpdated: questionsToBeUpdated});
        }
        this.state.questionsToBeUpdated.forEach(function (value, key) {
            console.log(key + ' = ' + value.caption + ", " + value.update + ", " + value.prompt);
        })
    }

    onChangePrompt(event) {
        /*update :1 means caption updated.
            2 means prompt updated
            3 means both*/
        var questionsToBeUpdated = this.state.questionsToBeUpdated;
        if (this.state.questionsToBeUpdated.get(event.target.name) == null) {
            console.log("this question was never changed before");
            var question = {category: this.state.selectedCategory, update: 2, prompt: event.target.value, caption: ''};
            questionsToBeUpdated.set(event.target.name, question);
            this.setState({questionsToBeUpdated: questionsToBeUpdated});
        } else {
            var questionToBeUpdated = this.state.questionsToBeUpdated.get(event.target.name);
            var updateType = questionToBeUpdated.update;
            console.log(updateType);
            if (updateType === 2 || updateType === 3) {
                console.log("just reset prompt");
                //just reset prompt
                questionToBeUpdated.prompt = event.target.value;
            } else {
                console.log("reset to 3 and rest prompt");
                //reset update to 3 because now both are being update
                questionToBeUpdated.update = 3;
                questionToBeUpdated.prompt = event.target.value;
            }
            questionsToBeUpdated.set(event.target.name, questionToBeUpdated)
            this.setState({questionsToBeUpdated: questionsToBeUpdated});
        }
        this.state.questionsToBeUpdated.forEach(function (value, key) {
            console.log(key + ' = ' + value.caption + ", " + value.update + ", " + value.prompt);
        })
    }

    async onChangeDropDownSelection(event) {
        console.log("in the on change drop down select" + event.target.value);
        await this.setState({selectedCategory: event.target.value});
        this.setState({questionsToBeDeleted: []})
        this.setState({questionToBeUpdated: new Map([])});
        this.setState({questionToBeAdded: {}, addAnotherMode: false});
        await this.fetchQuestions();
        return
    }

    fetchCategories() {
        fetch('/fetchCategories', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            withCredentials: true,
            credentials: 'include'
        }).then(res => {
            if (res.status == 404) {
                console.log("no categories")
                throw new Error("no categories found")
            }
            if (res.status === 401) {
                console.log("error 401: not authenticated to enter")
                this.props.logoutUser();
                this.setState({loggedIn: false});
                alert("logging out. session expired.");
                throw new Error("not authenticated to enter")
            }
            if (res.status === 200) {
                var data = res.json();
                console.log(data);
                return data;
            } else {
                throw new Error("something went wrong");
            }

        }).then(categoriesList => {
            console.log(categoriesList);
            this.setState({categories: categoriesList});
            return;
        }).then(() => {
            this.renderCategoriesDDL();
            return;
        }).then(() => {
            this.fetchQuestions();
            return;
        }).catch((error) => {
            console.log(error);
        })
    }

    fetchQuestions() {
        fetch('/fetchQuestions', {
            method: "POST",
            body: JSON.stringify({categoryId: parseInt(this.state.selectedCategory)}),
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true,
            credentials: 'include'
        }).then(res => {
            var data = res.json();
            console.log(data);
            if (res.status === 404) {
                console.log("response 404");
                this.setState({newPosition: data})
                throw new Error("now questions for this category")
            } else if (res.status === 401) {
                console.log("error 401: not authenticated to enter");
                this.props.logoutUser();
                this.setState({loggedIn: false});
                alert("logging out. session expired.");
                throw new Error("not authenticated to enter")
            }

            return data;
        }).then(questionList => {
            console.log(questionList);
            this.setState({
                isQuestionsForCategory: true,
                questions: questionList,
                clonedQuestions: clone(questionList)
            });
            return;

        }).then(() => {
            this.renderQuestionTableBody(this.state.questions);
            return;
        }).catch(e => {
            console.log(e);
            this.setState({
                isQuestionsForCategory: false,
                questions: [],
                renderQuestionTableBody: [<div>no questions for this category</div>]
            });
            return;
        });
    }

    onSubmitChanges(event) {
        if (this.state.deleteMode) {
            if (this.state.questionsToBeDeleted.length !== 0) {
                var isConfirmDelete = window.confirm("Are you sure you want to delete the selected questions?");
                if (isConfirmDelete === true) {
                    fetch("/deleteQuestion", {
                            method: "DELETE",
                            body: JSON.stringify({
                                questionIds: this.state.questionsToBeDeleted,
                                categoryId: this.state.selectedCategory
                            }),
                            headers: {
                                "Content-Type": "application/json"
                            },
                            withCredentials: true,
                            credentials: 'include'
                        }
                    ).then(response => {
                            if (response.status === 200) {
                                console.log("in response =200");
                                window.alert("question deleted successfully");

                                this.fetchQuestions();

                            } else if (response.status === 404) {
                                alert("status 404: delete unsuccessfull");
                            } else if (response.status === 401) {
                                console.log("error 401: not authenticated to enter");
                                this.props.logoutUser();
                                this.setState({loggedIn: false});
                                alert("logging out. session expired.");
                                throw new Error("not authenticated to enter")
                            }
                        }
                    ).catch((e) => {
                        console.log("delete unsuccessful")
                        console.log(e);
                    })
                    this.setState({deleteMode: false, questionsToBeDeleted: []});
                } else {
                    alert("delete cancelled");
                }
            } else {
                alert("no questions were selected to be deleted");
            }
        } else if (this.state.editMode) {
            console.log(this.state.questionsToBeUpdated);
            if (this.state.questionsToBeUpdated.size !== 0) {
                var isConfirmUpdate = window.confirm("Are you sure you want to update the questions you changed?");
                if (isConfirmUpdate === true) {
                    fetch("/updateQuestions", {
                            method: "PUT",
                            body: JSON.stringify({updatedQuestions: Array.from(this.state.questionsToBeUpdated.entries())}),
                            headers: {
                                "Content-Type": "application/json"
                            },
                            withCredentials: true,
                            credentials: 'include'
                        }
                    ).then(response => {
                            if (response.status === 200) {
                                console.log("in response =200");
                                window.alert("question updated successfully");
                                var x = document.getElementsByClassName('prompt-caption');
                                for (var i = 0; i < x.length; i++) {
                                    x[i].classList.remove('edit-focus');
                                }
                                this.fetchQuestions();

                            } else if (response.status === 404) {
                                console.log("in response =404");
                                alert("error 404: update cancelled");
                            } else if (response.status === 401) {
                                console.log("error 401: not authenticated to enter");
                                this.props.logoutUser();
                                this.setState({loggedIn: false});
                                alert("logging out. session expired.");
                                throw new Error("not authenticated to enter")
                            }
                        }
                    ).catch((e) => {
                        console.log("update unsuccessful")
                        console.log(e);
                    })
                    this.setState({editMode: false, questionsToBeUpdated: new Map([])});
                } else {
                    alert("update cancelled");
                }
            } else {
                alert("no edits were made");
            }

        } else if (this.state.addMode) {
            console.log(this.state.questionToBeAdded)
            fetch("/addQuestion", {
                    method: "POST",
                    body: JSON.stringify({question: this.state.questionToBeAdded}),
                    headers: {
                        "Content-Type": "application/json"
                    },
                    withCredentials: true,
                    credentials: 'include'
                }
            ).then(response => {
                    if (response.status === 201) {
                        console.log("in response =201");
                        window.alert("submission to database successful");
                        this.fetchQuestions();
                        if (this.state.addAnotherMode === true) {
                            //
                        } else {
                            this.setState({show: false, addMode: false, addAnotherMode: false, questionToBeAdded: {}});
                        }


                    } else if (response.status === 404) {
                        console.log("in response =404");
                        alert("error 404: add unsuccessful");
                    } else if (response.status === 401) {
                        console.log("error 401: not authenticated to enter");
                        this.props.logoutUser();
                        this.setState({loggedIn: false});
                        alert("logging out. session expired.");
                        throw new Error("not authenticated to enter")
                    }
                }
            ).catch((e) => {
                console.log("add unsuccessful")
                console.log(e);
            })

        } else if (this.state.reorderMode) {
            var isConfirmReorder = window.confirm("Are you sure you want reorder questions?");
            if (isConfirmReorder === true) {
                fetch("/reorderQuestions", {
                        method: "PUT",
                        body: JSON.stringify({questions: this.state.clonedQuestions}),
                        headers: {
                            "Content-Type": "application/json"
                        },
                        withCredentials: true,
                        credentials: 'include'
                    }
                ).then(response => {
                        if (response.status === 200) {
                            console.log("in response =200");
                            window.alert("reorder successful");
                            this.fetchQuestions();

                        } else if (response.status === 404) {
                            alert("status 404: reorder unsuccessfull");
                        } else if (response.status === 401) {
                            console.log("error 401: not authenticated to enter");
                            this.props.logoutUser();
                            this.setState({loggedIn: false});
                            alert("logging out. session expired.");
                            throw new Error("not authenticated to enter")
                        }
                    }
                ).catch((e) => {
                    console.log("reorder unsuccessful")
                    console.log(e);
                })
                this.setState({reorderMode: false});
            } else {
                alert("reorder cancelled");
            }
        }

    }

    onCheckQuestion(event) {
        const checked = event.target.checked;
        console.log("checked: " + checked);
        //add it to the questions to be deleted
        if (checked) {
            this.state.questionsToBeDeleted.push(parseInt(event.target.value));
        }
        //delete the question from the questions to be deleted
        if (!checked) {
            var index = this.state.questionsToBeDeleted.indexOf(parseInt(event.target.value));
            this.state.questionsToBeDeleted.splice(index, 1);
        }
        console.log("questions to be deleted: " + this.state.questionsToBeDeleted);
    }

    handleDrop(e) {
        // this / e.target is current target element.

        if (e.stopPropagation) {
            e.stopPropagation(); // stops the browser from redirecting.
        }
        console.log("in handle drag drop")
        var dropElement = e.target.parentElement.children[0].innerText;
        var dragElement = this.state.itemInDrag;

        var idToMove = parseInt(dragElement);
        var idToMoveItTo = parseInt(dropElement);
        console.log("ids: " + idToMove + "    " + idToMoveItTo)
        //make deep copy of this state.questions
        var questions = this.state.clonedQuestions;
        //check if equal 0
        console.log("before if in onplusreorder");

        //check if moving up or down
        if (idToMove > idToMoveItTo) {
            console.log("in id >move");
            //moving up
            questions.map(question => {
                //if equals id to move change position to the one you want to move it to
                if (question.position === idToMove) {
                    console.log("in make the move!");
                    question.position = idToMoveItTo;
                }
                //if less than id to move and greater than the move to
                else if (question.position < idToMove && question.position >= idToMoveItTo) {
                    question.position += 1;
                }

            });
        } else if (idToMove < idToMoveItTo) {
            console.log("in id <move");
            questions.map(question => {
                //moving down

                //if equals id to move change position to the one you want to move it to
                if (question.position === idToMove) {
                    console.log("in make the move!");
                    question.position = idToMoveItTo;
                }
                //everything greater than the move  and less than to the move to 
                else if (question.position > idToMove && question.position <= idToMoveItTo) {
                    console.log("in subtract position");
                    question.position -= 1;
                }
            });
        } else {
            console.log("no conditions met in onplusreorder")
        }
        console.log("after ifs in on plus reorder");
        this.renderQuestionTableBody(questions.sort((a, b) => (a.position > b.position) ? 1 : -1));
        var uniqueKey = this.state.uniqueKey + 1;
        this.setState({clonedQuestions: questions, uniqueKey: uniqueKey});

    }


    handleDragStart(e) {
        console.log("in handle drag start")
        e.target.style.opacity = '0.4';  // this / e.target is the source node.

        //savet he item in drag 
        this.setState({itemInDrag: e.target.firstElementChild.innerText});
        console.log(this.state.itemInDrag);
    }

    handleDragEnter(e) {
        console.log("in handle drag enter")
        // this / e.target is the current hover target.
        e.target.parentElement.classList.add('over');
    }

    handleDragEnd(e) {
        console.log("in handle drag end");
        e.target.style.opacity = '1';
        // this/e.target is the source node.
        var rows = document.querySelectorAll('.row');
        [].forEach.call(rows, function (row) {
            row.classList.remove('over');
        });  // this / e.target is previous target element.

    }

    handleDragLeave(e) {
        console.log("in handle drag leave");
        e.target.parentElement.classList.remove('over');
    }

    handleDragOver(e) {
        console.log("in handle drag over")
        if (e.preventDefault) {
            e.preventDefault(); // Necessary. Allows us to drop.
        }

        e.dataTransfer.dropEffect = 'move';  // See the section on the DataTransfer object.

        return false;
    }

    async renderQuestionTableBody(questions) {
        var renderedTable

        if (this.state.deleteMode) {
            console.log("in the question render table body deletemode");
            console.log(questions);
            renderedTable = questions.map((list, index) => (
                <tr key={index} value={list.position}>
                    <td className="checkbox"><label className="container"><input key={list.position + "chk"}
                                                                                 type="checkbox"
                                                                                 onClick={this.onCheckQuestion}
                                                                                 name="checkbox" value={list.position}>
                    </input> <span class="checkmark"></span></label></td>
                    <td className="id"><span className="position-index">{index + 1}</span></td>
                    <td>
                        <div className="prompt-caption">
                            <img className="img-camera" alt="" src={iconCamera}/>
                            <div class="line"></div>
                            <span className="prompt">{list.prompt}</span></div>
                    </td>
                    <td>
                        <div className="prompt-caption">
                            <img className="img-play" alt="" src={iconPlay}/>
                            <div class="line"></div>
                            <span className="caption">{list.default_caption}</span></div>
                    </td>
                </tr>
            ))
            await this.setState({renderQuestionTableBody: renderedTable});
            return;

        } else if (this.state.editMode) {
            console.log("in the question render table body last else");
            console.log(questions);
            renderedTable = questions.map((list, index) => (
                <tr key={index} value={list.position}>
                    <td><span className="position-index">{index + 1}</span></td>
                    <td>
                        <div className="prompt-caption">
                            <img className="img-camera" alt="" src={iconCamera}/>
                            <div class="line"></div>
                            <span className="prompt"><textarea className="textarea" key={list.position + "prompt"}
                                                               id={"prompt" + list.position}
                                                               onChange={this.onChangePrompt} rows="3"
                                                               name={list.position}>{list.prompt}</textarea></span>
                        </div>
                    </td>
                    <td>

                        <div className="prompt-caption">
                            <img className="img-play" alt="" src={iconPlay}/>
                            <div class="line"></div>
                            <span className="caption"><textarea className="textarea" key={list.position + "caption"}
                                                                id={"caption" + list.position}
                                                                onChange={this.onChangeCaption} rows="1"
                                                                name={list.position}>{list.default_caption}</textarea></span>
                        </div>
                    </td>
                </tr>
            ))
            this.setState({renderQuestionTableBody: renderedTable});
        } else if (this.state.reorderMode === true) {
            var display = {display: "none"};
            console.log("in the question render reorder");
            renderedTable = questions.map((list, index) => (
                <tr key={list.position} className="row" onDragLeave={this.handleDragLeave} allowDrop="true"
                    onDrop={this.handleDrop} draggable="true" onDragStart={this.handleDragStart}
                    onDragEnter={this.handleDragEnter} onDragOver={this.handleDragOver} onDragEnd={this.handleDragEnd}>
                    <td style={display}><span>{list.position}</span></td>
                    <td className="reorder-mode"><span className="position-index">{index + 1}</span></td>
                    <td>
                        <div className="prompt-caption">
                            <img className="img-camera" alt="" src={iconCamera}/>
                            <div class="line"></div>
                            <span className="prompt">{list.prompt}</span></div>
                    </td>
                    <td>
                        <div className="prompt-caption">
                            <img className="img-play" alt="" src={iconPlay}/>
                            <div class="line"></div>
                            <span className="caption">{list.default_caption}</span></div>
                    </td>
                </tr>

            ))
            this.setState({renderQuestionTableBody: renderedTable});
        } else {
            console.log("in the question render table body last else");
            renderedTable = questions.map((list, index) => (
                <tr key={index} value={list.position}>
                    <td><span className="position-index">{index + 1}</span></td>
                    <td>
                        <div className="prompt-caption">
                            <img className="img-camera" alt="" src={iconCamera}/>
                            <div class="line"></div>
                            <span className="prompt">{list.prompt}</span></div>
                    </td>
                    <td>
                        <div className="prompt-caption">
                            <img className="img-play" alt="" src={iconPlay}/>
                            <div class="line"></div>
                            <span className="caption">{list.default_caption}</span></div>
                    </td>
                </tr>
            ))
            this.setState({renderQuestionTableBody: renderedTable});
        }
    }

    renderCategoriesDDL() {
        this.state.categories.map((list, index) => {
            var count = this.state.categoriesCount;
            this.setState({categoriesCount: ++count})
            if (this.state.categoriesCount === 1) {
                this.setState({selectedCategory: list.category_id});
                console.log("in count =1");
                this.state.categoriesDDL.push(<option key={index} value={list.category_id} selected>
                    {list.name}
                </option>);
            } else {
                this.state.categoriesDDL.push(<option key={index} value={list.category_id}>
                    {list.name}
                </option>)
            }

        })
    }


    render() {
        /*if (this.state.isLoaded === false) {
            return null;
        }*/

        console.log("in home render");
        console.log(this.state.loggedIn);
        var newPosition = 0;
        if (this.state.questions.length !== 0) {
            newPosition = this.state.questions[this.state.questions.length - 1].position + 1;
        }

        return (
            <Fragment>
                <Modal show={this.state.show} handleClose={this.hideModal}>
                    <div className="modal-container">
                        <div>
                            <p className="modal-header">Add Questions</p>
                            <p className="modal-subheader">(All new questions will be added to the bottom of the
                                list)</p>
                        </div>

                        <div className="modal-body">
                            <div className="modal-add-question question">
                                <p className="add-question-header ">Question</p>
                                <p className="add-question-subheader modal-subheader">(Max three lines)</p>
                                <div className="prompt-caption">
                                    <img className="img-camera" alt="" src={iconCamera}/>
                                    <div class="line"></div>
                                    <span className="prompt"><textarea className="textarea"
                                                                       key={newPosition + "prompt"}
                                                                       id={"modal-prompt"}
                                                                       onChange={this.onChangeNewQuestionPrompt}
                                                                       rows="3"
                                                                       name={newPosition}></textarea></span>
                                </div>
                            </div>

                            <div className="modal-add-question">
                                <p className="add-question-header">Caption</p>
                                <p className="add-question-subheader  modal-subheader">(One Line)</p>
                                <div className="prompt-caption">
                                    <img className="img-play" alt="" src={iconPlay}/>
                                    <div class="line"></div>
                                    <span className="caption"><textarea className="textarea"
                                                                        key={newPosition + "caption"}
                                                                        id={"modal-caption"}
                                                                        onChange={this.onChangeNewQuestionCaption}
                                                                        rows="1"
                                                                        name={newPosition}></textarea></span>
                                </div>
                            </div>
                        </div>
                        <div className="modal-file-upload">
                            <div className="file-upload">
                                <input type="file" accept=".xls,.xlsx"
                                       onChange={event => this.onChangeFile(event)}></input>
                            </div>
                            <div className="modal-submit">
                                <input type="submit" className="edit-button edit"
                                       onClick={event => this.readAndSaveFile(event)}
                                       value="Upload file and Close"></input>
                            </div>
                        </div>
                        <div className="modal-submits">
                            <div id="submitChanges " className="modal-submit add-another">
                                <input type="button" className="edit-button save modal-button"
                                       onClick={event => this.saveAndAddAnother(event)}
                                       value="save & add another"></input>
                            </div>
                            <div id="submitChanges " className="modal-submit">
                                <input type="button" className="edit-button save modal-button"
                                       onClick={event => this.saveAndClose(event)} value="save & close"></input>
                            </div>
                        </div>
                    </div>
                </Modal>
                <div className="home-fragment">
                    <div className="left-column">
                        <div className="ddl-categories-prompt"> Please select a category:&nbsp;&nbsp;</div>
                        <div className="categories-ddl-container">

                            <label>
                                <select id="ddlCategories" className="ddl-categories"
                                        onChange={this.onChangeDropDownSelection}>
                                    {this.state.categoriesDDL}
                                </select>
                            </label>
                        </div>
                        <div>
                            <div id="editQuestion">
                                <input type="button" className="edit-button edit"
                                       onClick={event => this.onEditQuestion(event)} value="Edit questions"></input>
                            </div>
                            <div id="deleteQuestion">
                                <input type="button" className="edit-button edit"
                                       onClick={event => this.onDeleteQuestion(event)}
                                       value="delete questions"></input>
                            </div>
                            <div id="addQuestion">
                                <input type="button" className="edit-button edit"
                                       onClick={event => this.onAddQuestion(event)} value="Add questions"></input>
                            </div>
                            <div id="reorderQuestion">
                                <input type="button" className="edit-button edit"
                                       onClick={event => this.onReorderQuestion(event)}
                                       value="Reorder questions"></input>
                            </div>
                        </div>
                        <div id="submitChanges ">
                            <input type="button" className="edit-button save"
                                   onClick={event => this.onSubmitChanges(event)} value="Submit Changes"></input>
                        </div>
                    </div>
                    <table id="edittable" className="question-table">
                        <thead className="question-table-header">
                        <tr className="question-table-header-row">
                            {this.state.deleteMode ?
                                <th class="checkbox select-all"><label class="container"><input id="inputSelectAll"
                                                                                                onClick={this.deleteChkToggle}
                                                                                                key="selectAll"
                                                                                                type="checkbox">
                                </input> <span class="checkmark"></span></label></th> : ''}
                            <th className="header-id">ID</th>
                            <th>Question</th>
                            <th>Caption</th>
                        </tr>
                        </thead>
                        <tbody className="question-table-body">
                        {this.state.renderQuestionTableBody}
                        </tbody>
                    </table>
                    <div><img className="img-logo" src={iconLogo}/></div>
                </div>
                <div>
                </div>

            </Fragment>
        );


    }
}

export default Home;
