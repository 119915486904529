import React, {useEffect, useState} from 'react';
import Main from './pages/Main';
import Header from './pages/header'
import './App.css';
import {Auth} from "@aws-amplify/auth";
import {COGNITO} from "./aws";
import {useHistory} from "react-router";

const App = () => {
    const [state, setState] = useState({loggedIn: false});
    const history = useHistory();
    // const isAuthenticated = useIsAuthenticated();

    useEffect(() => {
        Auth.configure({
            aws_cognito_region: COGNITO.REGION,
            aws_user_pools_id: COGNITO.USER_POOL_ID,
            aws_user_pools_web_client_id: COGNITO.APP_CLIENT_ID,
        });


        Auth.currentAuthenticatedUser().then(value => {
            setState({loggedIn: Boolean(value)})
        }).catch(reason => {
            setState({loggedIn: false})
        })
    })


    const handleLogout = async () => {
        console.log('BEFORE LOCK')
        Auth.signOut()
            .then(value => {
                history.push("/login")
                // setState({loggedIn: false});
            })
        console.log('AFTER LOCK')

        /*await fetch("/logoutUser", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
            }
        ).then(response => {
                if (response.status === 200) {
                    console.log("in response =200");
                    setState({loggedIn: false});
                } else if (response.status === 404) {
                    console.log("in response =404 logout unsuccesful");

                }
            }
        ).catch((e) => {
            console.log("invalid credentials")
            console.log(e);
        })*/
    }

    return (

        <div id='container'>
            <div id='body'>
                {state.loggedIn ?
                    <div className="header">
                        <Header handleLogout={handleLogout}/>
                    </div> : ''}
                <Main
                    logInUser={() => {
                        console.log("in login user");
                        setState({loggedIn: true})
                    }}
                    logoutUser={handleLogout}
                    loggedIn={state.loggedIn}/>
            </div>

        </div>
    );

}

export default App;

